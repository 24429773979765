@font-face {
  font-family: "yekan";
  src: url("./fonts/Yekan_Bakh_Regular.woff") format("woff");
}
@font-face {
  font-family: "yekan-bold";
  src: url("./fonts/Yekan_Bakh_Bold.woff") format("woff");
}
html {
  scroll-behavior: smooth;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
select {
  font-family: "yekan";
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
label {
  font-family: "yekan-bold";
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
html,
body {
  width: 100%;
  height: 100%;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}
p {
  line-height: 27px;
}
a,
button {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/* change colours to suit your needs */

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
/* change colours to suit your needs */

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  font-family: "yekan-bold";
  display: inline-block;
  background: transparent;
  box-shadow: none;
  padding: none;
  border: none;
}
/* change border colour to suit your needs */

hr {
  max-width: 100%;
  border-color: #c0c0c0;
  border-width: 1px;
  margin: 30px auto;
  display: block;
}
input,
select {
  vertical-align: middle;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "yekan-bold";
  font-weight: 500;
}
:focus {
  outline: 0;
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
ul li {
  list-style: none;
}
body {
  direction: rtl;
  font-family: "yekan";
  overscroll-behavior-y: contain;
  font-size: 1rem;
}
.swal2-styled.swal2-confirm {
  background-color: #256d85 !important;
  color: #fff !important;
  padding: 15px !important;
  text-align: center !important;
  border-radius: 9px !important;
  cursor: pointer !important;
  box-shadow: none !important;
}
